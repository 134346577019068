import React from 'react';
import AdvertisementBox from '../components/AdvertisementBox';

function RightSidebarAdOnly() {
  return (
    <AdvertisementBox />
  );
}

export default RightSidebarAdOnly;
