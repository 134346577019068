const RegistartionSecurityList = [
  'What is the first movie you saw in a theater?',
  'What is your biggest fear?',
  'Who is the first band you saw in concert?',
  'City/town where your parents met?',
  'Name of your favorite teacher?',
  'Name of your first pet?',
  'What is your favorite restaurant?',
  'Name of your favorite relative?',
  'Name of your best friend?',
];

export default RegistartionSecurityList;
